import React, { useEffect } from 'react';

const AdBanner = ({ zoneId }) => {
    useEffect(() => {
        // Cargar el script de AdProvider
        const script = document.createElement('script');
        script.src = 'https://a.magsrv.com/ad-provider.js';
        script.async = true;
        script.type = 'application/javascript';

        // Cuando el script se carga, ejecutar el código para mostrar el anuncio
        script.onload = () => {
            // Asegurarse de que el AdProvider esté disponible
            if (window.AdProvider) {
                window.AdProvider.push({"serve": {}});
            }
        };

        document.body.appendChild(script);

        // Limpiar el efecto al desmontar el componente
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div>
            <ins className="eas6a97888e2" data-zoneid={zoneId}></ins>
        </div>
    );
};

export default AdBanner;